<template>
  <b-card no-body style="border-radius: 0.5rem !important py-0">
    <b-card-header header-tag="header" class="bg-transparent py-3" role="tab">
      <div v-b-toggle="[toggleId]" class="px-16 py-0">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="d-flex align-items-center mr-16">
            <p class="mb-0">
              {{ getNameQuestionWithOrder }}
            </p>
            <i
              v-if="!childs.length && type !== typesQuestions.group"
              :class="`${getStatusFromCompleteAnswers().icon} mx-3 ${
                getStatusFromCompleteAnswers().color
              }`"
              v-b-tooltip.hover
              :title="getStatusFromCompleteAnswers().text"
            >
            </i>
          </div>
          <section class="d-flex align-items-center">
            <b-button
              v-if="!childs.length && type !== typesQuestions.group"
              @click="emitCloseSidebarAndGetQuestion(id)"
              variant="outline-warning"
              class="mx-2 p-0"
              size="sm"
            >
              <i class="ri-eye-line mx-2" style="font-size: 15px"></i>
            </b-button>
            <i
              v-if="childs.length"
              class="ri-arrow-right-s-line arrow-item hp-transition text-black-60"
              style="font-size: 20px"
            ></i>
          </section>
        </div>
      </div>
    </b-card-header>
    <b-collapse
      v-if="childs.length"
      :id="toggleId"
      :accordion="toggleId"
      role="tabpanel"
    >
      <b-card-body style="background-color: ghostwhite">
        <item-sidebar-question
          @closeSidebarAndGetQuestion="emitCloseSidebarAndGetQuestion"
          v-for="questionSub in childs"
          :key="questionSub.id"
          :id="questionSub.id"
          :type="questionSub.question_type.name"
          :statusApproved="questionSub.status_approved"
          :toggleId="`accordion-sub-${questionSub.id}`"
          :order="`${order}.${questionSub.order}`"
          :name="`${questionSub.name}`"
          :childs="questionSub.child"
          :parent="questionSub.parent"
        >
        </item-sidebar-question>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: "ItemSidebarQuestion",
  props: {
    id: {
      required: true,
    },
    toggleId: {
      type: String,
      required: true,
    },
    statusApproved: {
      type: Number,
      required: true,
    },
    order: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    parent: {
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    childs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      typesQuestions: {
        group: "Grupos/SubGrupos",
      },
      statusApprovedList: {
        not_answers: {
          id: 0,
          color: "grey",
          text: "Sin respuestas cargadas",
          icon: "ri-star-line",
        },
        pending_answers: {
          id: 1,
          color: "text-success",
          text: "Hay pendientes de aprobación",
          icon: "ri-star-half-line",
        },
        all_approved: {
          id: 2,
          color: "text-primary",
          text: "Todas las respuestas aprobadas",
          icon: "ri-star-fill",
        },
      },
    };
  },
  methods: {
    emitCloseSidebarAndGetQuestion(id) {
      this.$emit("closeSidebarAndGetQuestion", id);
    },
    getStatusFromCompleteAnswers() {
      switch (this.statusApproved) {
        case this.statusApprovedList.not_answers.id:
          return this.statusApprovedList.not_answers;
          break;
        case this.statusApprovedList.pending_answers.id:
          return this.statusApprovedList.pending_answers;
          break;
        case this.statusApprovedList.all_approved.id:
          return this.statusApprovedList.all_approved;
          break;
      }
    },
  },
  computed: {
    getNameQuestionWithOrder() {
      return `${this.order}${!this.parent ? "." : ""} ${this.name}`;
    },
  },
};
</script>

<style></style>
