<template>
  <b-modal
    id="modal-answers-replacement"
    body-class="pt-2"
    hide-footer
    size="xl"
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <h5 class="mb-0">Detalles de sustitución</h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>
    <section>
      <b-row v-if="currentAnswer" align-h="center" class="mt-3">
        <b-col cols="12" md="6" sm="12" class="hp-flex-none">
          <h6>Foto rechazada</h6>
          <b-carousel
            id="carousel-answers-rejected"
            v-model="slideRejected"
            :controls="false"
            indicators
            :interval="0"
            class="w-100"
            fade
            background="#e3e3e3"
          >
            <!-- slides with image -->
            <b-carousel-slide class="carousel-img" background="#e3e3e3">
              <template #img>
                <b-img
                  class="d-block mx-auto image image-lazy slide-carousel-approved"
                  :src="getRejectAnswer.image"
                  :alt="getRejectAnswer.name"
                ></b-img>
              </template>
            </b-carousel-slide>
          </b-carousel>
          <p class="pt-2 mb-2 text-left mx-2">
            Cargada por:
            <b>
              {{
                `${getRejectAnswer.place_user[0].customuser.first_name} 
                  ${getRejectAnswer.place_user[0].customuser.last_name}`
              }}
            </b>
            el:
            <b>
              {{
                moment(getRejectAnswer.created_at).format(
                  "DD/MM/YYYY HH:mm [hrs]"
                )
              }}
            </b>
          </p>
          <p class="pt-0 mb-2 text-left mx-2">
            Rechazada por:
            <b class="font-weight-bold">
              {{
                getRejectAnswer.rejected_by.first_name
                  ? `${getRejectAnswer.rejected_by.first_name} ${getRejectAnswer.rejected_by.last_name}`
                  : "Super Administrador"
              }}
            </b>
            el:
            <b>
              {{
                moment(getRejectAnswer.rejected_at).format(
                  "DD/MM/YYYY HH:mm [hrs]"
                )
              }}
            </b>
          </p>
          <p class="pt-0 text-left mx-2">
            Motivo de rechazo:
            <b>
              {{
                getRejectAnswer.rejected_comment
                  ? getRejectAnswer.rejected_comment
                  : "No especificado"
              }}
            </b>
          </p>
        </b-col>
        <b-col cols="12" md="6" sm="12" class="hp-flex-none">
          <h6>Fotos de sustitución</h6>
          <div
            style="height: 250px"
            class="w-100 bg-grey d-flex align-items-center justify-content-center"
            v-if="!getReplacementAnswers.length"
          >
            <h4 class="text-gray">Imagen marcada como sustituida</h4>
          </div>
          <b-carousel
            id="carousel-answers-rejected"
            v-model="slide"
            :controls="false"
            indicators
            :interval="0"
            class="w-100"
            fade
            background="#e3e3e3"
          >
            <!-- slides with image -->
            <b-carousel-slide
              v-for="currentImage in getReplacementAnswers"
              :key="currentImage.id"
              class="carousel-img"
              background="#e3e3e3"
            >
              <template #img>
                <b-img
                  class="d-block mx-auto image image-lazy slide-carousel-approved"
                  :src="currentImage.image"
                  :alt="currentImage.name"
                ></b-img>
              </template>
            </b-carousel-slide>
          </b-carousel>
          <p class="pt-2 text-center" v-if="">
            {{ getCurrentAnswerReplacement ? "Cargada" : "Marcada" }} por:
            <b>
              {{
                getCurrentAnswerReplacement
                  ? `${getCurrentAnswerReplacement.place_user[0].customuser.first_name} 
                  ${getCurrentAnswerReplacement.place_user[0].customuser.last_name}`
                  : `${currentAnswer.answer_replacement.replacement[0].replaced_by.first_name}
                   ${currentAnswer.answer_replacement.replacement[0].replaced_by.last_name}`
              }}
            </b>
            el:
            <b>
              {{
                moment(
                  getCurrentAnswerReplacement
                    ? getCurrentAnswerReplacement.created_at
                    : currentAnswer.answer_replacement.replacement[0].created_at
                ).format("DD/MM/YYYY HH:mm [hrs]")
              }}
            </b>
          </p>
        </b-col>
      </b-row>
    </section>
    <br />
    <div class="d-flex justify-content-end flex-wrap mt-2">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
export default {
  props: {
    currentAnswer: {
      default: null,
    },
    currentQuestion: {
      default: null,
    },
  },
  data() {
    return {
      moment,
      slideRejected: 0,
      slide: 0,
    };
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-answers-replacement");
    },
  },
  computed: {
    getRejectAnswer() {
      return this.currentAnswer.answer_replacement.replacement.length
        ? this.currentQuestion.answers.find(
            (item) =>
              item.id ==
              this.currentAnswer.answer_replacement.replacement[0]
                .rejected_answer
          )
        : this.currentQuestion.answers.find(
            (item) =>
              item.id ==
              this.currentAnswer.answer_replacement.rejected[0].rejected_answer
          );
    },
    getReplacementAnswers() {
      return this.currentAnswer.answer_replacement.replacement.length
        ? this.currentQuestion.answers.filter((item) =>
            this.currentAnswer.answer_replacement.replacement.some(
              (answer) => answer.replacement_answer == item.id
            )
          )
        : this.currentQuestion.answers.filter((item) =>
            this.currentAnswer.answer_replacement.rejected.some(
              (answer) => answer.replacement_answer == item.id
            )
          );
    },
    getCurrentAnswerReplacement() {
      return this.getReplacementAnswers[this.slide]
        ? this.getReplacementAnswers[this.slide]
        : null;
    },
  },
};
</script>

<style>
.slide-carousel-approved {
  height: auto !important;
  max-height: 300px !important;
}
</style>
