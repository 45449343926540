<template>
  <b-modal
    id="modal-question-comments"
    body-class="pt-2"
    hide-footer
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <h5 class="mb-0">Comentarios</h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>

    <b-form class="mb-8">
      <b-row
        class="mx-0 mb-3 scroll-vertical"
        style="max-height: 300px"
        v-if="comments.length"
      >
        <b-col
          cols="12"
          class="hp-bg-black-0 hp-bg-dark-100 border hp-border-color-black-10 hp-border-color-dark-80 pt-0 mb-32"
          style="border-radius: 24px"
          v-for="(item, index) in comments"
          :key="index"
        >
          <b-row align-h="between" align-v="center">
            <b-col cols="12" md="12" sm="12" class="mb-16 mb-sm-0">
              <b-row align-v="start">
                <b-col class="hp-flex-none w-auto px-0">
                  <div
                    class="hp-cursor-pointer border hp-border-color-dark-80 mr-2"
                    style="border-radius: 15px"
                  >
                    <div
                      class="rounded-lg overflow-hidden d-flex align-items-center"
                    >
                      <b-avatar
                        variant="none"
                        :src="require('@/assets/img/app/user/user-icon.png')"
                        size="32px"
                        class="rounded-0 hp-bg-info-4"
                      />
                    </div>
                  </div>
                </b-col>
                <b-col class="hp-flex-none w-auto px-0">
                  <span class="d-block h6 mb-0 ml-1">{{
                    item.user.first_name
                      ? `${item.user.first_name} ${" "} ${item.user.last_name}`
                      : "Super Administrador"
                  }}</span>
                </b-col>
              </b-row>
              <span
                style="margin-top: -0.8rem"
                class="d-block hp-p1-body ml-32"
                >{{ item.text }}</span
              >
              <small class="ml-32">
                {{ moment(item.created_at).format("DD/MM/YYYY HH:mm [hrs]") }}
              </small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <p v-else class="text-center mb-5">Sin comentarios..</p>
      <b-form-group
        label="Agregar nuevo comentario:"
        label-for="newComment"
        class="mb-16 px-2"
      >
        <b-form-textarea
          :disabled="
            ((!currentPlaceUser ||
              !checkIfHaveRole(
                currentPlaceUser ? currentPlaceUser.rol.id : null,
                [],
                true
              )) &&
              (!currentUser ||
                !checkIfHaveRole(currentUser.rol, [
                  ROLES.inspector,
                  ROLES.coordinator,
                  ROLES.superadmin,
                ]))) ||
            currentQuestionIsDisabled
          "
          v-model="form.text"
          id="newComment"
          cols="8"
          rows="4"
          placeholder="Comentario.."
          :class="{ 'is-invalid': $v.form.text.$error }"
        ></b-form-textarea>
        <div v-if="$v.form.text.$error" class="invalid-feedback">
          <span v-if="!$v.form.text.required">Comentario es requerido.</span>
        </div>
      </b-form-group>
      <b-button
        type="button"
        v-show="
          (currentPlaceUser &&
            checkIfHaveRole(
              currentPlaceUser ? currentPlaceUser.rol.id : null,
              [],
              true
            )) ||
          (currentUser &&
            checkIfHaveRole(currentUser.rol, [
              ROLES.inspector,
              ROLES.coordinator,
              ROLES.superadmin,
            ]))
        "
        :disabled="currentQuestionIsDisabled"
        class="float-right"
        variant="outline-primary"
        @click="handleSubmit"
      >
        <b-spinner v-if="loading" small type="grow" class="mr-8"></b-spinner>
        Enviar comentario
      </b-button>
    </b-form>
    <br />
    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import moment from "moment";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [validationMixin, verifyRole],
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    placeId: {
      required: true,
    },
    questionId: {
      required: true,
    },
    currentPlaceUser: {
      default: null,
    },
    currentQuestionIsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment,
      loading: false,
      form: {
        text: "",
      },
    };
  },
  validations: {
    form: {
      text: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      createQuestionComment: "question/createQuestionComment",
    }),
    async handleSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const { ...rest } = this.form;
        let response = await this.createQuestionComment({
          place_id: this.placeId,
          user_id: this.currentUser.id,
          question: this.questionId,
          ...rest,
        });
        this.loading = false;
        if (response.status == 201) {
          this.$bvToast.toast(`Comentario agregado correctamente`, {
            title: `Excelente`,
            variant: "primary",
            solid: false,
          });
          this.form.text = "";
          this.$nextTick(() => {
            this.$v.$reset();
          });
          setTimeout(() => {
            this.$emit("newComment");
          }, 1000);
        }
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-question-comments");
      this.form = {
        text: "",
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>

<style></style>
