export const TYPE_GROUP = 1
export const TYPE_QUESTION = 2
export const QUESTION_TYPES = {
    gallery_image: 2,
    image: 3,
    open_answer: 4,
    number_answer: 5,
    closed_answer: 6,
    pdf_answer: 7,
    video_answer: 8,
    select_answer: 9,
    image_text: 10
}