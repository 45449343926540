<template>
  <b-modal
    id="modal-add-missing-answer-image"
    body-class="pt-2"
    hide-footer
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <h5 class="mb-0">Añadir foto faltante</h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>

    <b-form class="mb-8">
      <b-form-group
        label="Agregar Comentario:"
        label-for="commentImage"
        class="mb-16 px-2"
      >
        <b-form-textarea
          v-model="form.comment"
          id="newComment"
          cols="8"
          rows="4"
          placeholder="Comentario.."
          :class="{ 'is-invalid': $v.form.comment.$error }"
        ></b-form-textarea>
        <div v-if="$v.form.comment.$error" class="invalid-feedback">
          <span v-if="!$v.form.comment.required">Comentario es requerido.</span>
        </div>
      </b-form-group>
    </b-form>
    <br />
    <div class="d-flex justify-content-end flex-wrap mt-16">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
      <b-button
        class="ml-2"
        type="button"
        variant="warning"
        @click="handleSubmit"
      >
        <b-spinner v-if="loading" small type="grow" class="mr-8"></b-spinner>
        Añadir
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import moment from "moment";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [validationMixin, verifyRole],
  props: {
    placeId: {
      required: true,
    },
    questionId: {
      required: true,
    },
  },
  data() {
    return {
      moment,
      loading: false,
      form: {
        comment: "",
      },
    };
  },
  validations: {
    form: {
      comment: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      addMissingAnswer: "question/addMissingAnswer",
    }),
    async handleSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const { ...rest } = this.form;
        let response = await this.addMissingAnswer({
          id_place: this.placeId,
          id_question: this.questionId,
          ...rest,
        });
        this.loading = false;
        if (response.status == 201) {
          this.$bvToast.toast(`Foto faltante agregada correctamente`, {
            title: `Excelente`,
            variant: "primary",
            solid: false,
          });
          this.form.comment = "";
          this.$nextTick(() => {
            this.$v.$reset();
          });
          setTimeout(() => {
            this.$emit("newMissingImage");
          }, 1000);
        }
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-add-missing-answer-image");
      this.form = {
        comment: "",
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>

<style></style>
