<template>
  <section>
    <span
      class="my-2"
      :class="{
        'font-weight-bold': !child,
      }"
    >
      {{ order }}{{ !parent ? "." : "" }} {{ name }}
    </span>
    <br />
    <small v-if="!child" v-html="description"></small>
    <item-question-name
      class="my-2"
      v-if="child"
      :child="child.child"
      :parent="child.parent"
      :order="`${order}.${child.order}`"
      :name="child.name"
      :description="child.description"
    >
    </item-question-name>
  </section>
</template>

<script>
export default {
  name: "ItemQuestionName",
  props: {
    parent: {
      default: null,
    },
    child: {
      default: null,
    },
    order: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
  },
};
</script>

<style></style>
