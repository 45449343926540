<template>
  <b-row>
    <b-col cols="12" class="mb-32 mt-16">
      <b-row align-h="between" class="mt-n32">
        <b-col v-if="checklist" class="hp-flex-none w-auto mt-32">
          <breadcrumb :activeTitle="checklist.name" :items="breadcrumb" />
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="6" class="mb-32" v-if="checklist">
      <h2 class="mb-0">{{ checklist.name }}</h2>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">
        Responder plantilla
      </h5>
    </b-col>

    <b-col cols="6" class="mb-32">
      <section class="d-flex justify-content-end align-items-end">
        <div class="mx-5 d-flex align-items-center">
          <span class="mt-px"> Solo pendientes de aprobación </span>
          <b-form-checkbox
            switch
            size="lg"
            class="mx-2"
            @change="nextOrPrevQuestion(undefined)"
            :disabled="!anyQuestionHaveAnswersPendingApproved"
            v-model="filters.only_pending_approved"
          >
          </b-form-checkbox>
        </div>
        <div>
          <b-button
            v-b-toggle.questions-sidebar
            variant="outline-warning"
            size="sm"
            class="mt-3"
          >
            <small> Todas las preguntas </small>
          </b-button>
        </div>
        <sidebar-list-questions
          :questions="questions"
          @closeSidebarAndGetQuestion="nextOrPrevQuestion"
        ></sidebar-list-questions>
      </section>
    </b-col>
    <b-col cols="12">
      <b-card
        class="border-0 p-2"
        body-class="hp-knowledge-basic-card text-center"
      >
        <div v-if="currentQuestion">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="text-justify">
              <item-question-name
                v-if="currentQuestion && parentQuestion"
                :parent="parentQuestion.parent"
                :child="parentQuestion.child"
                :order="`${parentQuestion.order}`"
                :name="parentQuestion.name"
                :description="parentQuestion.description"
              >
              </item-question-name>
            </div>
            <div
              class="d-flex align-items-center justify-content-center"
              v-if="
                currentUser &&
                checkIfHaveRole(currentUser.rol, [
                  ROLES.inspector,
                  ROLES.coordinator,
                  ROLES.superadmin,
                ])
              "
            >
              <b-button
                v-if="
                  ifAnswersActive &&
                  (currentQuestionType == QUESTION_TYPES.gallery_image ||
                    currentQuestionType == QUESTION_TYPES.image ||
                    currentQuestionType == QUESTION_TYPES.image_text) &&
                  getAnswerFromCurrentSlide()
                "
                @click="openModalAddMissingAnswerImage()"
                :disabled="currentQuestionIsDisabled"
                variant="warning"
                size="sm"
                class="ml-3 py-0 px-3"
              >
                <small> Agregar Foto Faltante </small>
              </b-button>
              <b-button
                v-if="
                  ifAnswersActive &&
                  ifAnyAnswerNotAddedToDocument &&
                  (currentQuestionType == QUESTION_TYPES.gallery_image ||
                    currentQuestionType == QUESTION_TYPES.image ||
                    currentQuestionType == QUESTION_TYPES.image_text) &&
                  getAnswerFromCurrentSlide()
                "
                @click="openModalAddAllAnswersToDocument()"
                :disabled="currentQuestionIsDisabled"
                variant="warning"
                size="sm"
                class="ml-3 py-0 px-3"
              >
                <small>
                  {{
                    loading
                      ? "Aprobando"
                      : "Agregar todas las respuestas al reporte"
                  }}
                </small>
                <b-spinner
                  v-if="loading"
                  small
                  type="grow"
                  class="ml-3 my-2"
                ></b-spinner>
              </b-button>
              <b-button
                @click="openModalAproveAllAnswersPlaceChecklist()"
                :disabled="currentQuestionIsDisabled"
                variant="warning"
                size="sm"
                class="ml-3 py-0 px-3"
              >
                <small>
                  {{
                    loading
                      ? "Actualizando"
                      : "Aprobar todas las respuestas de la plantilla"
                  }}
                </small>
                <b-spinner
                  v-if="loading"
                  small
                  type="grow"
                  class="ml-3 my-2"
                ></b-spinner>
              </b-button>
              <b-button
                @click="openModalUpdateAllAnswersToHd()"
                :disabled="currentQuestionIsDisabled"
                variant="warning"
                size="sm"
                class="ml-3 py-0 px-3"
              >
                <small>
                  {{
                    loading
                      ? "Actualizando"
                      : "Cambiar a HD todas las respuestas"
                  }}
                </small>
                <b-spinner
                  v-if="loading"
                  small
                  type="grow"
                  class="ml-3 my-2"
                ></b-spinner>
              </b-button>
              <b-button
                v-if="
                  ifAnswersActive &&
                  ifAnyAnswerNotApproved &&
                  getAnswerFromCurrentSlide()
                "
                @click="openModalApproveAllAnswers()"
                :disabled="currentQuestionIsDisabled"
                variant="warning"
                size="sm"
                class="ml-3 py-0 px-3"
              >
                <small>
                  {{
                    loadingAddAnswersToDocument
                      ? "Aprobando"
                      : "Aprobar todas las respuestas"
                  }}
                </small>
                <b-spinner
                  v-if="loadingAddAnswersToDocument"
                  small
                  type="grow"
                  class="ml-3 my-2"
                ></b-spinner>
              </b-button>
              <b-button
                v-if="ifAnswersActive && getAnswerFromCurrentSlide()"
                @click="
                  openModalUpdateApprovedAnswer(
                    getAnswerFromCurrentSlide().id,
                    getAnswerFromCurrentSlide().approved_at ? false : true
                  )
                "
                :variant="
                  getAnswerFromCurrentSlide().approved_at
                    ? 'success'
                    : 'primary'
                "
                :disabled="currentQuestionIsDisabled"
                size="sm"
                class="mx-3 py-0 px-3"
              >
                <small>
                  {{
                    getAnswerFromCurrentSlide().approved_at
                      ? "Desaprobar respuesta"
                      : "Aprobar respuesta"
                  }}
                </small>
              </b-button>
              <b-button
                v-if="
                  ifAnswersActive &&
                  (currentQuestionType == QUESTION_TYPES.gallery_image ||
                    currentQuestionType == QUESTION_TYPES.image ||
                    currentQuestionType == QUESTION_TYPES.image_text)
                "
                @click="openModalRejectAnswer"
                variant="success"
                :disabled="currentQuestionIsDisabled"
                size="sm"
                class="px-3 py-0'"
              >
                <small> Rechazar Foto </small>
              </b-button>
              <b-button
                v-if="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.superadmin,
                  ])
                "
                @click="openModalUpdateEnabledQuestion"
                :disabled="loadingEnableQuestion"
                pill
                variant="info"
                size="sm"
                class="p-0 ml-3"
                id="question-disabled"
              >
                <i
                  class="lh-2 mx-2"
                  :class="{
                    'ri-eye-off-line': currentQuestionIsDisabled,
                    'ri-eye-line': !currentQuestionIsDisabled,
                  }"
                ></i>
                <b-spinner
                  v-if="loadingEnableQuestion"
                  small
                  type="grow"
                  class="px-1 mr-8"
                ></b-spinner>
              </b-button>
              <b-tooltip
                target="question-disabled"
                v-if="currentQuestionIsDisabled"
              >
                <p>Habilitar Pregunta</p>
                Deshabilitada por:
                <b>
                  {{
                    `${currentQuestion.disbled_for_this_place.created_by.first_name} ${currentQuestion.disbled_for_this_place.created_by.last_name}`
                  }}
                </b>
                el:
                <b>
                  {{
                    moment(
                      currentQuestion.disbled_for_this_place.created_at
                    ).format("DD/MM/YYYY HH:mm [hrs]")
                  }}
                </b>
              </b-tooltip>
              <b-tooltip target="question-disabled" v-else>
                Deshabilitar pregunta
              </b-tooltip>
            </div>
          </div>

          <section
            v-if="
              currentQuestionType == QUESTION_TYPES.gallery_image ||
              currentQuestionType == QUESTION_TYPES.image ||
              currentQuestionType == QUESTION_TYPES.image_text
            "
            :style="{ position: 'relative', height: `${currentImageHeight}px` }"
          >
            <section style="position: absolute" class="w-100">
              <b-skeleton-img
                class="mt-5 pt-5"
                no-aspect
                :height="`${currentImageHeight}px`"
              ></b-skeleton-img>
            </section>

            <b-carousel
              id="checklist-carousel"
              class="w-100"
              v-model="slide"
              indicators
              fade
              :interval="0"
              background="#e3e3e3"
              @sliding-end="updateCurrentImageHeight"
            >
              <!-- Text slides with image -->
              <b-carousel-slide
                v-if="imagesQuestion.length"
                class="carousel-img"
                background="#e3e3e3"
                v-for="(currentImage, index) in imagesQuestion"
                :key="currentImage.id"
              >
                <div
                  class="d-flex align-items-center justify-content-center"
                  :style="{
                    height: `${currentImageHeight}px`,
                  }"
                  v-if="currentQuestionIsDisabled"
                >
                  <i
                    style="font-size: 120px"
                    class="mt-5 ri-eye-off-line text-info lh-2 mx-2"
                  ></i>
                </div>
                <section
                  v-if="
                    getAnswerFromCurrentSlide() &&
                    getAnswerFromCurrentSlide().answer_replacement &&
                    getAnswerFromCurrentSlide().answer_replacement.replacement
                      .length
                  "
                >
                  <b-button
                    @click="
                      openModalAnswerReplacement(getAnswerFromCurrentSlide())
                    "
                    :disabled="currentQuestionIsDisabled"
                    variant="warning"
                    class="mb-32 mx-3"
                  >
                    <span class="mb-0">Sustitución</span>
                    <i class="ri-eye-line lh-2 mx-2"></i>
                    Ver
                  </b-button>
                </section>
                <template #img v-if="currentImage.id">
                  <b-img-lazy
                    v-bind="mainProps"
                    style="
                      height: auto !important;
                      max-height: 600px !important;
                    "
                    :style="{
                      opacity: currentQuestionIsDisabled ? 0.6 : 1,
                    }"
                    class="d-block mx-auto image image-lazy"
                    :src="currentImage.image"
                    alt="Imagen de plantilla"
                  >
                  </b-img-lazy>
                </template>
                <h1
                  v-if="!ifAnswersActive && currentQuestion"
                  class="text-white shadow"
                >
                  Ejemplo
                </h1>
              </b-carousel-slide>
            </b-carousel>
          </section>
          <section
            class="mx-2"
            v-if="currentQuestionType == QUESTION_TYPES.open_answer"
          >
            <b-form-textarea
              v-model="form.open_answer"
              :disabled="currentQuestionIsDisabled"
              id="open_answer"
              cols="4"
              rows="7"
              :placeholder="currentQuestion.description"
            ></b-form-textarea>
          </section>
          <section
            class=""
            v-if="currentQuestionType == QUESTION_TYPES.number_answer"
          >
            <b-form-input
              v-model="form.number_answer"
              type="number"
              :disabled="currentQuestionIsDisabled"
              id="open_answer"
              class="w-50 mx-auto"
              :placeholder="currentQuestion.description"
            ></b-form-input>
          </section>
          <section
            class=""
            v-if="currentQuestionType == QUESTION_TYPES.closed_answer"
          >
            <b-form-radio-group
              v-model="form.closed_answer"
              name="closed_answer"
              :disabled="currentQuestionIsDisabled"
            >
              <b-form-radio value="si">
                <h5>Si</h5>
              </b-form-radio>
              <b-form-radio value="no">
                <h5>No</h5>
              </b-form-radio>
            </b-form-radio-group>
          </section>
          <section
            class=""
            v-if="currentQuestionType == QUESTION_TYPES.select_answer"
          >
            <b-form-select
              v-model="form.select_answer"
              :disabled="currentQuestionIsDisabled"
              :options="[
                { name: currentQuestion.description, id: null, disabled: true },
                ...currentQuestionOptions,
              ]"
              id="selectAnswer"
              class="mb-1 w-50"
              text-field="name"
              value-field="id"
            ></b-form-select>
          </section>
          <section
            class="d-flex justify-content-center align-items-center"
            v-if="currentQuestionType == QUESTION_TYPES.pdf_answer"
          >
            <b-form-file
              v-model="form.pdf_answer"
              accept="application/pdf"
              :disabled="currentQuestionIsDisabled"
              id="pdf_answer"
              class="w-50 mb-2"
              browse-text="Buscar"
              :placeholder="currentQuestion.description"
            ></b-form-file>
            <b-button
              v-if="form.show_pdf"
              :href="form.show_pdf"
              :disabled="currentQuestionIsDisabled"
              target="_blank"
              variant="warning"
              class="mx-5"
            >
              <i class="ri-file-download-line lh-2 mx-2"></i>
              Descargar Documento
            </b-button>
          </section>
          <section
            class=""
            v-if="currentQuestionType == QUESTION_TYPES.video_answer"
          >
            <video
              class="mx-auto mb-3 mt-5"
              width="80%"
              height="300"
              controls
              v-if="form.show_video"
            >
              <source
                :src="form.show_video"
                :type="getMimeTypeFromUrl(form.show_video)"
              />
            </video>
            <br />
            <div
              class="d-flex align-items-center justify-content-center mb-1"
              v-if="loadingChunked"
            >
              <b-spinner variant="warning" class="mx-2 my-2"></b-spinner>
              <span class="my-1 px-1">Subiendo Video</span>
              <span class="mx-1" v-if="percentageUploadedChunked">
                {{ Math.trunc(percentageUploadedChunked) }} %
              </span>
            </div>
            <b-form-file
              :disabled="currentQuestionIsDisabled"
              v-model="form.video_answer"
              :accept="VIDEO_EXTENSIONS"
              id="video_answer"
              class="w-50 my-2"
              browse-text="Cargar video"
              :placeholder="currentQuestion.description"
            ></b-form-file>
          </section>

          <div class="pt-2" v-if="getAnswerFromCurrentSlide()">
            <div v-if="getAnswerFromCurrentSlide()">
              <p v-if="currentQuestionType == QUESTION_TYPES.image_text">
                <span class="mx-2">Texto de la imagen: </span>
                <span v-if="getAnswerFromCurrentSlide().value">{{
                  getAnswerFromCurrentSlide().value
                }}</span>
              </p>
            </div>

            <div
              v-if="getAnswerFromCurrentSlide().is_answer"
              class="d-flex align-items-center justify-content-center my-3"
            >
              <b-badge
                class="mx-2 px-0"
                pill
                size="sm"
                variant="warning"
                id="user-upload-answer"
              >
                <i class="ri-user-line lh-2 mx-2"></i>
              </b-badge>
              <b-tooltip
                target="user-upload-answer"
                v-if="getAnswerFromCurrentSlide().place_user"
              >
                Cargada por:
                <b>
                  {{
                    `${
                      getAnswerFromCurrentSlide().place_user.customuser
                        .first_name
                    } ${
                      getAnswerFromCurrentSlide().place_user.customuser
                        .last_name
                    }`
                  }}
                </b>
                el:
                <b>
                  {{
                    moment(getAnswerFromCurrentSlide().created_at).format(
                      "DD/MM/YYYY HH:mm [hrs]"
                    )
                  }}
                </b>
              </b-tooltip>
              <b-badge
                id="status-answer"
                class="px-2 py-2"
                :variant="
                  getAnswerFromCurrentSlide() &&
                  getAnswerFromCurrentSlide().approved_at
                    ? 'primary'
                    : 'info'
                "
              >
                {{
                  getAnswerFromCurrentSlide().approved_at
                    ? "Aprobada"
                    : "Pendiente de aprobación"
                }}
              </b-badge>
              <b-tooltip
                target="status-answer"
                v-if="getAnswerFromCurrentSlide().approved_at"
              >
                Aprobada por
                <b>
                  {{ getAnswerFromCurrentSlide().approved_by.first_name }}
                  {{ getAnswerFromCurrentSlide().approved_by.last_name }}
                </b>
                el:
                <b>
                  {{
                    moment(getAnswerFromCurrentSlide().approved_at).format(
                      "DD/MM/YYYY HH:mm [hrs]"
                    )
                  }}
                </b>
              </b-tooltip>
            </div>
          </div>

          <div class="d-flex flex-column" v-if="currentQuestion">
            <div
              class="mx-auto d-flex justify-content-center align-items-center"
              v-if="
                getAnswerFromCurrentSlide() &&
                getAnswerFromCurrentSlide().is_answer &&
                (currentQuestionType == QUESTION_TYPES.gallery_image ||
                  currentQuestionType == QUESTION_TYPES.image ||
                  currentQuestionType == QUESTION_TYPES.image_text) &&
                currentUser &&
                checkIfHaveRole(currentUser.rol, [
                  ROLES.inspector,
                  ROLES.coordinator,
                  ROLES.superadmin,
                ])
              "
            >
              <div
                class="text-center"
                :class="{
                  'text-primary': getAnswerFromCurrentSlide().pdf_render,
                  'text-gray': !getAnswerFromCurrentSlide().pdf_render,
                }"
              >
                {{
                  getAnswerFromCurrentSlide().pdf_render
                    ? "Esta imagen estará en el Reporte"
                    : "Esta imagen no se incluirá en el Reporte"
                }}
              </div>
              <b-button
                @click="
                  openModalUpdatePdfRenderFromAnswer(
                    getAnswerFromCurrentSlide().id,
                    getAnswerFromCurrentSlide().pdf_render
                  )
                "
                :disabled="currentQuestionIsDisabled"
                :variant="
                  getAnswerFromCurrentSlide().pdf_render ? `danger` : 'warning'
                "
                size="sm"
                class="px-3 mx-3 py-0'"
              >
                <small>
                  {{
                    getAnswerFromCurrentSlide().pdf_render
                      ? "Retirar del reporte"
                      : "Agregar al reporte"
                  }}
                </small>
              </b-button>
              <div
                class="d-flex align-items-center justify-content-center mx-2"
                v-if="getAnswerFromCurrentSlide().pdf_render"
              >
                <span class="mx-2"> Calidad de Foto </span>
                <b-form-checkbox
                  :disabled="currentQuestionIsDisabled"
                  class="check-hd-image"
                  v-model="getAnswerFromCurrentSlide().show_in_hd"
                  @change="
                    updateShowHdImageFromAnswer(
                      getAnswerFromCurrentSlide().id,
                      getAnswerFromCurrentSlide().show_in_hd
                    )
                  "
                  name="check-button"
                  switch
                >
                </b-form-checkbox>
                <span>
                  <b>
                    {{
                      getAnswerFromCurrentSlide().show_in_hd ? "HD" : "Normal"
                    }}
                  </b>
                </span>
              </div>
            </div>
            <div
              class="d-flex mt-3 align-items-center flex-column flex-xs-row flex-sm-row justify-content-center"
            >
              <b-button
                @click="nextOrPrevQuestion(currentQuestion.near[0])"
                variant="text"
                size="lg"
                :class="{
                  hide: !currentQuestion.near[0],
                }"
              >
                <i class="ri-arrow-left-line lh-2 mx-2 my-2"></i>
                Anterior
              </b-button>
              <b-button
                :disabled="currentQuestionIsDisabled"
                v-if="
                  currentQuestionType == QUESTION_TYPES.gallery_image ||
                  currentQuestionType == QUESTION_TYPES.image ||
                  currentQuestionType == QUESTION_TYPES.image_text
                "
                v-show="
                  (currentPlaceUser &&
                    checkIfHaveRole(
                      currentPlaceUser ? currentPlaceUser.rol.id : null,
                      [],
                      true
                    )) ||
                  (currentUser &&
                    checkIfHaveRole(currentUser.rol, [
                      ROLES.inspector,
                      ROLES.coordinator,
                      ROLES.superadmin,
                    ]))
                "
                @click="openFileFinder"
                variant="outline-success-2"
                size="lg"
                class="mx-5"
              >
                <i class="ri-camera-3-line lh-2 mx-2" v-if="!loading"></i>
                <b-spinner
                  v-if="loading"
                  small
                  type="grow"
                  class="mr-8 my-2"
                ></b-spinner>
                Cargar Foto
              </b-button>
              <b-button
                v-else
                :disabled="currentQuestionIsDisabled"
                @click="sendAnswerText"
                v-show="
                  (currentPlaceUser &&
                    checkIfHaveRole(
                      currentPlaceUser ? currentPlaceUser.rol.id : null,
                      [],
                      true
                    )) ||
                  (currentUser &&
                    checkIfHaveRole(currentUser.rol, [
                      ROLES.inspector,
                      ROLES.coordinator,
                      ROLES.superadmin,
                    ]))
                "
                variant="outline-success-2"
                size="lg"
                class="mx-5"
              >
                <b-spinner
                  v-if="loading"
                  small
                  type="grow"
                  class="mr-8 my-2"
                ></b-spinner>
                Enviar Respuesta
              </b-button>
              <b-button
                @click="nextOrPrevQuestion(currentQuestion.near[1])"
                variant="text"
                size="lg"
                class="my-2"
                :class="{
                  hide: !currentQuestion.near[1],
                }"
              >
                Siguiente
                <i class="ri-arrow-right-line lh-2 mx-2"></i>
              </b-button>
            </div>
            <input
              :disabled="currentQuestionIsDisabled"
              id="file-input"
              @change="
                (e) =>
                  currentQuestionType == QUESTION_TYPES.image_text
                    ? openModalTextAnswer(e)
                    : setFileAnswerAndSend(e)
              "
              class="mt-3 d-none"
              type="file"
              accept=".jpg,.jpeg,.png"
            />
          </div>
        </div>
        <div v-else class="mt-5">
          <b-spinner
            type="grow"
            style="width: 5rem; height: 5rem"
            variant="success"
            class="mx-auto"
          ></b-spinner>
        </div>
      </b-card>

      <b-card
        v-if="currentQuestion"
        class="border-0 p-2 mt-36"
        body-class=" text-center"
      >
        <b-row align-content="center" align-v="center">
          <b-col cols="12" lg="4" sm="12">
            <b-progress
              :value="progress"
              :max="100"
              class="mb-2"
              height="1.5rem"
              variant="primary"
            >
              <b-progress-bar :value="progress"> </b-progress-bar>
            </b-progress>
            <p clas="mb-0">
              Completado: <strong>{{ progress }} %</strong>
            </p>
          </b-col>
          <b-col cols="12" lg="3" md="12" sm="12">
            <span
              :class="getStatusCurrentQuestion.color"
              class="py-2 ml-3 my-2 status-text"
              >Estatus: <b>{{ getStatusCurrentQuestion.text }}</b></span
            >
          </b-col>
          <b-col cols="12" lg="5" md="12" sm="12">
            <b-button
              v-b-modal.modal-question-comments
              variant="outline-warning"
              size="sm"
              class="px-3 py-0 mx-3 my-2"
            >
              <small> Comentarios ({{ getWrappedComments.length }}) </small>
              <i class="ri-chat-1-line lh-2 mx-1"></i>
            </b-button>
            <modal-question-comments
              @newComment="emitNewQuestionComment"
              :comments="getWrappedComments"
              :placeId="modelId"
              :questionId="currentQuestion.id"
              :currentPlaceUser="currentPlaceUser"
              :currentQuestionIsDisabled="currentQuestionIsDisabled"
            >
            </modal-question-comments>
            <b-button
              v-if="
                currentQuestionType == QUESTION_TYPES.gallery_image ||
                currentQuestionType == QUESTION_TYPES.image ||
                currentQuestionType == QUESTION_TYPES.image_text
              "
              v-b-modal.modal-answers-rejected
              :disabled="
                !imagesRejectedQuestion.length || currentQuestionIsDisabled
              "
              variant="outline-danger"
              size="sm"
              class="px-3 py-0 my-2 mx-2"
            >
              <small> Fotos rechazadas </small>
              <i class="ri-camera-off-line lh-2 mx-2"></i>
            </b-button>
            <modal-answers-rejected
              ref="modalAnswerRejected"
              @answerRetrieved="emitAnswerRetrieved"
              @answerMarkReplaced="emitAnswerMarkReplaced"
              @deleteAnswer="sendDeleteAnswer"
              @replaceCurrrentImage="openFileFinder"
              @emitOpenModalAnswerReplacement="openModalAnswerReplacement"
              :answersRejected="imagesRejectedQuestion"
            >
            </modal-answers-rejected>
            <modal-answers-replacement
              ref="modal-answers-replacement"
              :currentAnswer="currentAnswerReplacement"
              :currentQuestion="currentQuestion"
            >
            </modal-answers-replacement>
            <modal-add-missing-answer-image
              @newMissingImage="emitNewMissingImage"
              :placeId="modelId"
              :questionId="currentQuestion.id"
            >
            </modal-add-missing-answer-image>
          </b-col>
        </b-row>
      </b-card>
      <b-modal
        ref="modal-reject-answer"
        header-class="align-items-center"
        body-class="pt-2"
        hide-footer
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">¿Estas seguro de rechazar esta imagen?.</h5>
          <b-button
            variant="text"
            @click="closeModalRejectAnswer"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </template>
        <h6></h6>
        <form>
          <b-form-textarea
            placeholder="Motivo de rechazo (Opcional)"
            v-model="form.rejected_comment"
            cols="8"
            rows="4"
          ></b-form-textarea>
        </form>
        <div class="d-flex justify-content-end flex-wrap mt-32">
          <b-button variant="outline-info-3" @click="closeModalRejectAnswer">
            Cancelar
          </b-button>
          <b-button
            type="button"
            variant="outline-danger ml-3"
            @click="sendRejectAnswer"
          >
            <b-spinner
              v-if="loading"
              small
              type="grow"
              class="mr-8"
            ></b-spinner>
            Rechazar Foto
          </b-button>
        </div>
      </b-modal>

      <b-modal
        ref="modal-text-answer"
        header-class="align-items-center"
        body-class="pt-2"
        hide-footer
        per
      >
        <template #modal-header>
          <h5 class="mb-0">Texto de la Imagen.</h5>
        </template>
        <form ref="form">
          <b-form-group
            label="Texto"
            label-for="text-input"
            invalid-feedback="Name is required"
            :state="imageTextState"
          >
            <b-form-textarea
              v-model="form.image_text"
              id="text-input"
              placeholder="Texto"
              cols="8"
              rows="4"
              :state="imageTextState"
              required
            ></b-form-textarea>
          </b-form-group>
        </form>
        <div class="d-flex justify-content-end flex-wrap mt-32">
          <b-button
            type="submit"
            variant="outline-success-2 ml-3"
            @click="closeModalTextAnswer"
          >
            <b-spinner
              v-if="loading"
              small
              type="grow"
              class="mr-8"
            ></b-spinner>
            Guardar Imagen con Texto
          </b-button>
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import ModalQuestionComments from "./ModalQuestionComments.vue";
import ModalAnswersRejected from "./ModalAnswersRejected.vue";
import { mapGetters, mapActions } from "vuex";
import ItemQuestionName from "./ItemQuestionName.vue";
import { QUESTION_TYPES } from "@/constants/question";
import { EXTENSION_VIDEO_MAP, VIDEO_EXTENSIONS } from "@/constants/common";
import { geExtensionFileFromUrl } from "@/utils/common";
import { verifyRole } from "@/mixins/role";
import moment from "moment";
import ModalAnswersReplacement from "./ModalAnswersReplacement.vue";
import SidebarListQuestions from "./SidebarListQuestions.vue";
import ModalAddMissingAnswerImage from "./ModalAddMissingAnswerImage.vue";

export default {
  mixins: [verifyRole],
  props: {
    currentQuestion: {
      default: null,
    },
    currentPlaceUser: {
      default: null,
    },
    placeChecklist: {
      default: null,
    },
    checklist: {
      default: null,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    breadcrumb: {
      type: Array,
      default: () => [],
    },
    modelId: {
      required: true,
    },
    createAnswer: {
      type: Function,
      default: () => {},
    },
    rejectAnswer: {
      type: Function,
      default: () => {},
    },
    deleteAnswer: {
      type: Function,
      default: () => {},
    },
    progress: {
      type: Number,
      required: true,
    },
  },
  components: {
    Breadcrumb,
    PageTitle,
    ModalQuestionComments,
    ModalAnswersRejected,
    ItemQuestionName,
    ModalAnswersReplacement,
    SidebarListQuestions,
    ModalAddMissingAnswerImage,
  },
  data() {
    return {
      loading: false,
      loadingApprovedAll: false,
      loadingAddAnswersToDocument: false,
      loadingEnableQuestion: false,
      selectedFile: null,
      slide: 0,
      STATUS_ANSWER_ACTIVE: 1,
      parentQuestion: null,
      mainProps: {
        center: true,
        blank: true,
        blankColor: "#ababab",
      },
      form: {
        open_answer: "",
        number_answer: null,
        closed_answer: null,
        pdf_answer: null,
        show_pdf: null,
        video_answer: null,
        show_video: null,
        select_answer: null,
        rejected_comment: "",
      },
      filters: {
        only_pending_approved: false,
      },
      currentQuestionOptions: [],
      currentAnswerToReplaceId: null,
      currentAnswerReplacement: null,
      QUESTION_TYPES,
      VIDEO_EXTENSIONS,
      currentImageHeight: 600,
      moment,
      imageTextState: null,
      chagedFile: null,
    };
  },
  methods: {
    ...mapActions({
      updatePdfRenderFromAnswer: "question/updatePdfRenderFromAnswer",
      getQuestionOptionsFromQuestion: "question/getQuestionOptionsFromQuestion",
      updateAnswer: "question/updateAnswer",
      updateApprovedAnswer: "question/updateApprovedAnswer",
      approveAllAnswers: "question/approveAllAnswers",
      addAllAnswersToDocument: "question/addAllAnswersToDocument",
      updateEnableQuestion: "question/updateEnableQuestion",
      updateApprovedAnswerListQuestion:
        "question/updateApprovedAnswerListQuestion",
      uploadVideoChunckedFromAnswer: "question/uploadVideoChunckedFromAnswer",
      setAllAnswersHdFromPlaceChecklist:
        "checklist/setAllAnswersHdFromPlaceChecklist",
      approveAllAnswersFromPlaceChecklist:
        "checklist/approveAllAnswersFromPlaceChecklist",
    }),
    openFileFinder(answerToReplaceId = null) {
      this.currentAnswerToReplaceId = isNaN(answerToReplaceId)
        ? null
        : answerToReplaceId;
      document.getElementById("file-input").click();
    },
    nextOrPrevQuestion(id) {
      this.slide = 0;
      this.$emit(
        "nextOrPrevQuestion",
        id,
        this.filters.only_pending_approved ? true : undefined
      );
    },
    getMimeTypeFromUrl(url) {
      const extension = geExtensionFileFromUrl(url);
      return EXTENSION_VIDEO_MAP[extension];
    },
    ifAnswerIsUploadedByUserAuth() {
      const currentImage = this.getAnswerFromCurrentSlide();
      if (currentImage) {
        const { place_user } = currentImage;
        return place_user.customuser.id === this.currentUser.id;
      }
    },
    async sendAnswerText() {
      let payload = {
        place_id: this.modelId,
        user_id: this.currentUser.id,
        question: this.currentQuestion.id,
      };
      if (
        this.currentQuestionType == QUESTION_TYPES.pdf_answer ||
        this.currentQuestionType == QUESTION_TYPES.video_answer
      ) {
        const fileValue = this.getAnswerFileFromCurrentQuestionType();

        if (
          this.currentQuestionType == QUESTION_TYPES.pdf_answer &&
          (!fileValue || fileValue.type !== "application/pdf")
        ) {
          return this.$bvToast.toast(`El formato del archivo debe ser .pdf`, {
            title: `Error`,
            variant: "danger",
            solid: false,
          });
        }
        if (
          this.currentQuestionType == QUESTION_TYPES.video_answer &&
          (!fileValue ||
            !VIDEO_EXTENSIONS.split(",").some(
              (extension) => extension == fileValue.type
            ))
        ) {
          return this.$bvToast.toast(
            `Los formatos de video permitidos son: ${VIDEO_EXTENSIONS}`,
            {
              title: `Error`,
              variant: "danger",
              solid: false,
            }
          );
        }
        payload["image"] = fileValue;
      } else {
        const answerValue = this.getAnswerValueFromCurrentQuestionType();
        if (!answerValue) {
          this.loading = false;
          return this.$bvToast.toast(`La respuesta es requerida`, {
            title: `Error`,
            variant: "danger",
            solid: false,
          });
        }
        payload["value"] = answerValue;
      }
      this.loading = true;
      let response;
      if (this.currentQuestionType == QUESTION_TYPES.video_answer) {
        response = await this.uploadVideoChunckedFromAnswer(payload);
      } else {
        response = await this.createAnswer(payload);
      }
      if (response.status == 500) {
        this.loading = false;
        this.$bvToast.toast(`Error en el servidor, intentalo nuevamente`, {
          title: `error`,
          variant: "danger",
          solid: false,
        });
      }
      if (response.status == 201) {
        this.$bvToast.toast(`Respuesta enviada correctamente`, {
          title: `Excelente`,
          variant: "primary",
          solid: false,
        });
        this.$emit("newAnswer");
      }
      this.loading = false;
    },
    async setFileAnswerAndSend(e) {
      if (this.currentAnswerToReplaceId) {
        this.$refs["modalAnswerRejected"].loading = true;
      }
      if (e.target.files.length) {
        this.selectedFile = e.target.files[0];
        this.loading = true;
        const bodyAnswer = {
          place_id: this.modelId,
          user_id: this.currentUser.id,
          question: this.currentQuestion.id,
          image: this.selectedFile,
          replace: this.currentAnswerToReplaceId ?? undefined,
        };
        if (this.currentQuestionType == QUESTION_TYPES.image_text) {
          const answerValue = this.getAnswerValueFromCurrentQuestionType();
          bodyAnswer.value = answerValue;
        }
        let response = await this.createAnswer(bodyAnswer);
        if (response.status == 201) {
          this.$bvToast.toast(
            !this.currentAnswerToReplaceId
              ? `Foto agregada correctamente`
              : `Foto sustituida correctamente`,
            {
              title: `Excelente`,
              variant: "primary",
              solid: false,
            }
          );
          e.target.value = "";
          this.chagedFile = null;
          if (this.currentAnswerToReplaceId) {
            this.$refs["modalAnswerRejected"].loading = false;
            this.closeModalRejectAnswer();
          }
          this.$emit("newAnswer");
        }
        this.loading = false;
      }
    },
    openModalUpdatePdfRenderFromAnswer(id_answer, pdf_render) {
      this.$bvModal
        .msgBoxConfirm(
          `¿Estas seguro de ${
            pdf_render ? "retirar la imagen del" : "agregar la imagen al"
          } reporte?. `,
          {
            size: "sm",
            buttonSize: "sm",
            okVariant: `${pdf_render ? "danger" : "warning"}`,
            okTitle: `${pdf_render ? "Retirar" : "Agregar"}`,
            cancelTitle: "Cancelar",
            cancelVariant: "outline-info-3",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            const resp = await this.updatePdfRenderFromAnswer({
              id_answer,
            });
            if (resp.status == 200 || resp.status == 201) {
              this.$bvToast.toast(
                `Imagen ${
                  pdf_render ? "retirada del reporte" : "agregada al reporte"
                } correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
              this.currentQuestion.answers.forEach((item) => {
                if (item.id === id_answer) {
                  item.pdf_render = !pdf_render;
                }
              });
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
    openModalUpdateApprovedAnswer(id_answer, approved) {
      this.$bvModal
        .msgBoxConfirm(
          `¿Estas seguro de ${
            approved ? "aprobar" : "desaprobar"
          } esta respuesta?`,
          {
            size: "sm",
            buttonSize: "sm",
            okVariant: `${approved ? "primary" : "success"}`,
            okTitle: `${approved ? "Aprobar" : "Desaprobar"}`,
            cancelTitle: "Cancelar",
            cancelVariant: "outline-info-3",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            const resp = await this.updateApprovedAnswer({
              id_answer,
              approved,
            });
            if (resp.status == 200 || resp.status == 201) {
              this.$bvToast.toast(
                `Respuesta ${
                  approved ? "aprobada" : "desaprobada"
                } correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
              this.currentQuestion.answers.forEach((item) => {
                if (item.id === id_answer) {
                  item.approved_at = approved ? new Date() : null;
                  item.approved_by = {
                    first_name: this.currentUser.first_name,
                    last_name: this.currentUser.last_name,
                  };
                }
              });
              if (approved) {
                this.updateApprovedAnswerListQuestion({
                  question_id: this.currentQuestion.id,
                  answer_id: answer_id,
                  user: {
                    first_name: this.currentUser.first_name,
                    last_name: this.currentUser.last_name,
                  },
                });
              }
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
    openModalApproveAllAnswers() {
      this.$bvModal
        .msgBoxConfirm(
          `¿Estas seguro de aprobar todas las respuestas de esta pregunta?`,
          {
            size: "sm",
            buttonSize: "sm",
            okVariant: `warning`,
            okTitle: `Aprobar todas`,
            cancelTitle: "Cancelar",
            cancelVariant: "outline-info-3",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            this.loadingApprovedAll = true;
            const resp = await this.approveAllAnswers({
              id_question: this.currentQuestion.id,
              id_place: this.$route.params.id,
            });
            if (resp.status == 200 || resp.status == 201) {
              this.$bvToast.toast(
                `Todas las respuestas fueron aprobadas correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
              this.currentQuestion.answers.forEach((item) => {
                item.approved_at = new Date();
                item.approved_by = {
                  first_name: this.currentUser.first_name,
                  last_name: this.currentUser.last_name,
                };
              });
              this.updateApprovedAnswerListQuestion({
                question_id: this.currentQuestion.id,
                user: {
                  first_name: this.currentUser.first_name,
                  last_name: this.currentUser.last_name,
                },
              });
            }
            this.loadingApprovedAll = false;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    openModalUpdateAllAnswersToHd() {
      this.$bvModal
        .msgBoxConfirm(
          `¿Estas seguro de cambiar todas las imagenes de esta plantilla a HD?, Después solo se podrá colocar en calidad normal imagen por imagen`,
          {
            size: "sm",
            buttonSize: "sm",
            okVariant: `warning`,
            okTitle: `Cambiar a HD`,
            cancelTitle: "Cancelar",
            cancelVariant: "outline-info-3",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            this.loading = true;
            const resp = await this.setAllAnswersHdFromPlaceChecklist({
              place_checklist_id: this.placeChecklist.id,
            });
            if (resp.status == 200 || resp.status == 201) {
              this.$bvToast.toast(
                `Todas las respuestas fueron cambiadas a HD correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
              this.currentQuestion.answers.forEach((item) => {
                item.show_in_hd = true;
              });
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    openModalAproveAllAnswersPlaceChecklist() {
      this.$bvModal
        .msgBoxConfirm(
          `¿Estas seguro de aprobar todas las respuestas de esta plantilla?`,
          {
            size: "sm",
            buttonSize: "sm",
            okVariant: `warning`,
            okTitle: `Aprobar`,
            cancelTitle: "Cancelar",
            cancelVariant: "outline-info-3",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            this.loading = true;
            const resp = await this.approveAllAnswersFromPlaceChecklist({
              place_checklist: this.placeChecklist.id,
            });
            if (resp.status == 200 || resp.status == 201) {
              this.$bvToast.toast(
                `Todas las respuestas fueron de la plantilla fueron aprobadas correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
              this.currentQuestion.answers.forEach((item) => {
                item.approved_at = new Date();
                item.approved_by = {
                  first_name: this.currentUser.first_name,
                  last_name: this.currentUser.last_name,
                };
              });
              this.updateApprovedAnswerListQuestion({
                question_id: this.currentQuestion.id,
                user: {
                  first_name: this.currentUser.first_name,
                  last_name: this.currentUser.last_name,
                },
              });
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    openModalAddAllAnswersToDocument() {
      this.$bvModal
        .msgBoxConfirm(
          `¿Estas seguro de agregar todas las respuestas aprobadas de esta pregunta al reporte?`,
          {
            size: "sm",
            buttonSize: "sm",
            okVariant: `warning`,
            okTitle: `Agregar`,
            cancelTitle: "Cancelar",
            cancelVariant: "outline-info-3",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            this.loadingAddAnswersToDocument = true;
            const resp = await this.addAllAnswersToDocument({
              id_question: this.currentQuestion.id,
              id_place: this.$route.params.id,
            });
            if (resp.status == 200 || resp.status == 201) {
              this.$bvToast.toast(
                `Todas las respuestas aprobadas fueron agregadas al reporte correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
              this.currentQuestion.answers
                .filter((item) => item.approved_at)
                .forEach((item) => {
                  item.pdf_render = true;
                });
            }
            this.loadingAddAnswersToDocument = false;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    openModalUpdateEnabledQuestion() {
      this.$bvModal
        .msgBoxConfirm(
          `¿Estas seguro de ${
            this.currentQuestionIsDisabled ? "Habilitar" : "Deshabilitar"
          } esta pregunta para esta plantilla?`,
          {
            size: "sm",
            buttonSize: "sm",
            okVariant: `warning`,
            okTitle: `${
              this.currentQuestionIsDisabled ? "Habilitar" : "Deshabilitar"
            }`,
            cancelTitle: "Cancelar",
            cancelVariant: "outline-info-3",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            this.loadingEnableQuestion = true;
            const resp = await this.updateEnableQuestion({
              id_blacklist_question:
                this.currentQuestion.disbled_for_this_place?.id,
              id_question: this.currentQuestion.id,
              id_place: this.$route.params.id,
            });
            if (resp.status == 200 || resp.status == 201) {
              this.$bvToast.toast(
                `Estatus de pregunta modificada correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
              this.$emit("updateEnableQuestion", this.currentQuestion.id);
            }
            this.loadingEnableQuestion = false;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    async updateShowHdImageFromAnswer(id_answer, show_in_hd) {
      const resp = await this.updateAnswer({
        id_answer,
        show_in_hd,
        question: this.currentQuestion.id,
      });
      if (resp.status == 200 || resp.status == 201) {
        this.$bvToast.toast(`Calidad de imagen modificada correctamente`, {
          title: `Excelente`,
          variant: "primary",
          solid: false,
        });
        this.currentQuestion.answers.forEach((item) => {
          if (item.id === id_answer) {
            item.show_in_hd = show_in_hd;
          }
        });
      }
    },
    async sendRejectAnswer() {
      const { id } = this.getAnswerFromCurrentSlide();
      this.loading = true;
      const resp = await this.rejectAnswer({
        id,
        rejected_comment: this.form.rejected_comment ?? undefined,
      });
      if (resp.status == 200 || resp.status == 201) {
        this.$bvToast.toast(`Imagen rechazada correctamente`, {
          title: `Excelente`,
          variant: "primary",
          solid: false,
        });
        this.$emit("answerRejected");
      }
      this.loading = false;
      this.closeModalRejectAnswer();
    },
    openModalAddMissingAnswerImage() {
      this.$bvModal.show("modal-add-missing-answer-image");
    },
    closeModalAddMissingAnswerImage() {
      this.$bvModal.show("modal-add-missing-answer-image");
    },
    openModalAnswerReplacement(answer) {
      this.currentAnswerReplacement = answer;
      this.$bvModal.show("modal-answers-replacement");
    },
    closeModalAnswerReplacement() {
      this.currentAnswerReplacement = null;
      this.$bvModal.hide("modal-answers-replacement");
    },
    openModalRejectAnswer() {
      this.form.rejected_comment = "";
      this.$refs["modal-reject-answer"].show();
    },
    closeModalRejectAnswer() {
      this.$refs["modal-reject-answer"].hide();
    },
    openModalTextAnswer(e) {
      this.chagedFile = e;
      this.form.image_text = null;
      this.$refs["modal-text-answer"].show();
    },
    closeModalTextAnswer() {
      const valid = this.$refs.form.checkValidity();
      this.imageTextState = valid;
      if (valid) {
        this.$refs["modal-text-answer"].hide();
        this.setFileAnswerAndSend(this.chagedFile);
      }
    },
    emitAnswerRetrieved() {
      this.$emit("answerRetrieved");
    },
    emitAnswerMarkReplaced() {
      this.$emit("answerMarkReplaced");
    },
    emitNewMissingImage() {
      this.$emit("newMissingImage");
    },
    async sendDeleteAnswer(id) {
      const resp = await this.deleteAnswer(id);
      if (resp.status == 200 || resp.status == 201) {
        this.$bvToast.toast(`Imagen eliminada correctamente`, {
          title: `Excelente`,
          variant: "primary",
          solid: false,
        });
      }
      this.$emit("answerDeleted");
    },
    resetForm() {
      this.form = {
        open_answer: "",
        number_answer: null,
        closed_answer: null,
        pdf_answer: null,
        show_pdf: null,
        video_answer: null,
        show_video: null,
        select_answer: null,
      };
    },
    getAnswerFromCurrentSlide() {
      return this.imagesQuestion[this.slide]
        ? this.imagesQuestion[this.slide]
        : null;
    },
    getAnswerValueFromCurrentQuestionType() {
      switch (this.currentQuestion.question_type.id) {
        case QUESTION_TYPES.open_answer:
          return this.form.open_answer;
        case QUESTION_TYPES.number_answer:
          return this.form.number_answer;
        case QUESTION_TYPES.closed_answer:
          return this.form.closed_answer;
        case QUESTION_TYPES.select_answer:
          return this.form.select_answer;
        case QUESTION_TYPES.image_text:
          return this.form.image_text;
        default:
          return null;
      }
    },
    getAnswerFileFromCurrentQuestionType() {
      switch (this.currentQuestion.question_type.id) {
        case QUESTION_TYPES.pdf_answer:
          return this.form.pdf_answer;
        case QUESTION_TYPES.video_answer:
          return this.form.video_answer;
        default:
          return null;
      }
    },
    async setForm(answers) {
      if (
        this.currentQuestion.question_type.id == QUESTION_TYPES.select_answer
      ) {
        this.currentQuestionOptions = await this.getQuestionOptionsFromQuestion(
          this.currentQuestion.id
        );
      }
      if (answers.length) {
        switch (this.currentQuestion.question_type.id) {
          case QUESTION_TYPES.open_answer:
            this.form.open_answer = answers[0].value;
            break;
          case QUESTION_TYPES.number_answer:
            this.form.number_answer = Number(answers[0].value);
            break;
          case QUESTION_TYPES.closed_answer:
            this.form.closed_answer = answers[0].value;
            break;
          case QUESTION_TYPES.select_answer:
            this.form.select_answer = answers[0].value;
            break;
          case QUESTION_TYPES.pdf_answer:
            this.form.show_pdf = answers[0].image;
            break;
          case QUESTION_TYPES.video_answer:
            this.form.show_video = answers[0].image;
            break;
          default:
            break;
        }
      } else {
        this.resetForm();
      }
    },
    setParentQuestion(question, child) {
      question.child = child;
      if (question.parent && isNaN(question.parent)) {
        this.setParentQuestion(question.parent, question);
      } else {
        this.parentQuestion = question;
      }
    },
    emitNewQuestionComment() {
      this.$emit("newQuestionComment");
    },
    setCurrentImageHeight() {
      let imgCarousel = document.getElementById("checklist-carousel");
      if (
        this.imagesQuestion.length &&
        imgCarousel &&
        imgCarousel.offsetHeight === 1
      ) {
        setTimeout(() => {
          this.setCurrentImageHeight();
        }, 300);
      }
      this.currentImageHeight = imgCarousel
        ? imgCarousel.offsetHeight > 600 || imgCarousel.offsetHeight <= 1
          ? 600
          : imgCarousel.offsetHeight
        : 600;
    },
    updateCurrentImageHeight(time = 300) {
      setTimeout(() => {
        this.setCurrentImageHeight();
      }, time);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      loadingChunked: "question/loadingChunked",
      percentageUploadedChunked: "question/percentageUploadedChunked",
    }),
    currentQuestionType() {
      if (this.currentQuestion) {
        return this.currentQuestion.question_type.id;
      }
      return null;
    },
    currentQuestionIsDisabled() {
      return (
        this.currentQuestion &&
        this.currentQuestion.disbled_for_this_place != false
      );
    },
    ifAnswersActive() {
      return (
        this.currentQuestion &&
        this.currentQuestion.answers.filter(
          (answer) => answer.status === this.STATUS_ANSWER_ACTIVE
        ).length
      );
    },
    ifAnyAnswerNotApproved() {
      return (
        this.currentQuestion &&
        this.currentQuestion.answers.length &&
        this.currentQuestion.answers.some(
          (answer) =>
            answer.approved_at === null &&
            answer.status === this.STATUS_ANSWER_ACTIVE
        )
      );
    },
    ifAnyAnswerNotAddedToDocument() {
      return (
        this.currentQuestion &&
        this.currentQuestion.answers.length &&
        this.currentQuestion.answers.some((answer) => {
          return (
            !answer.pdf_render && answer.status === this.STATUS_ANSWER_ACTIVE
          );
        })
      );
    },
    getStatusCurrentQuestion() {
      if (this.ifAnswersActive) {
        return {
          color: "text-primary",
          text: "Respondida",
        };
      }
      return {
        color: "text-info-3",
        text: "Pendiente",
      };
    },
    imagesQuestion() {
      if (
        this.currentQuestion &&
        this.currentQuestion.answers.length &&
        this.ifAnswersActive
      ) {
        return this.currentQuestion.answers
          .filter((answer) => answer.status === this.STATUS_ANSWER_ACTIVE)
          .map((answer) => ({
            ...answer,
            place_user: answer.place_user[0],
            is_answer: true,
          }));
      }
      if (this.currentQuestion && this.currentQuestion.placeholders.length) {
        return this.currentQuestion.placeholders.map((placeholder) => ({
          id: placeholder.id,
          image: placeholder.image,
          is_answer: false,
        }));
      }
      return [];
    },
    imagesRejectedQuestion() {
      if (this.currentQuestion.answers.length) {
        return this.currentQuestion.answers
          .filter((answer) => answer.status !== this.STATUS_ANSWER_ACTIVE)
          .map((answer) => ({
            ...answer,
            place_user: answer.place_user[0],
          }));
      }
      return [];
    },
    getWrappedComments() {
      return this.currentQuestion.comments.map((comment) => {
        return {
          ...comment,
          user: comment.place_user[0].customuser,
        };
      });
    },
    anyQuestionHaveAnswersPendingApproved() {
      return (
        this.placeChecklist &&
        this.placeChecklist.checklist.status.pending_percent > 0
      );
    },
  },
  watch: {
    currentQuestion(newQuestion) {
      this.parentQuestion = null;
      if (newQuestion) {
        this.setForm(newQuestion.answers);
        if (newQuestion.parents.length) {
          return this.setParentQuestion(newQuestion.parents[0], {
            ...newQuestion,
          });
        }
      }
      return (this.parentQuestion = newQuestion);
    },
    imagesQuestion(images) {
      if (images.length) {
        this.updateCurrentImageHeight(300);
      }
    },
  },
};
</script>
<style>
.hide {
  visibility: hidden;
}

#checklist-carousel {
  text-shadow: 1px 1px 2px #333;
  position: absolute;
  max-height: 600px !important;
}

.check-hd-image .custom-control-input ~ .custom-control-label::before {
  border-color: #28a745;
  background-color: #28a745;
}

.check-hd-image .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #dc3545;
  background-color: #dc3545;
}
</style>
