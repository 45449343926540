<template>
  <b-modal
    id="modal-answers-rejected"
    body-class="pt-2"
    hide-footer
    size="xl"
    header-class="align-items-center"
  >
    <template #modal-header="{ close }">
      <h5 class="mb-0">Fotos Rechazadas</h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>
    <section>
      <b-carousel
        id="carousel-answers-rejected"
        v-model="slide"
        controls
        indicators
        :interval="0"
        style="text-shadow: 1px 1px 2px #333"
        background="#ababab"
      >
        <!-- slides with image -->
        <b-carousel-slide
          v-for="currentImage in answersRejected"
          :key="currentImage.id"
        >
          <section>
            <b-button
              v-if="
                getCurrentAnswer &&
                getCurrentAnswer.answer_replacement.rejected.length
              "
              @click="openModalAnswerReplacement(getCurrentAnswer)"
              variant="primary"
              class="mb-2 mx-3 text-white"
            >
              <span class="mb-0">Sustituida</span>
              <i class="ri-eye-line lh-2 mx-2"></i>
              Ver
            </b-button>
            <b-badge v-else variant="success" class="mb-2 mx-3">
              <span class="mb-0 text-white">Pendiente de sustitución</span>
            </b-badge>
          </section>
          <template #img>
            <img
              style="height: auto !important; max-height: 700px !important"
              class="d-block mx-auto"
              :src="currentImage.image"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div class="mx-auto text-center">
        <p
          class="mt-3"
          v-if="getCurrentAnswer && getCurrentAnswer.rejected_comment"
        >
          Motivo de rechazo:
          <br />
          <small class="font-weight-bold">
            {{
              getCurrentAnswer.rejected_comment
                ? getCurrentAnswer.rejected_comment
                : "No especificado"
            }}
          </small>
        </p>
        <p class="mt-3" v-if="getCurrentAnswer && getCurrentAnswer.rejected_at">
          Rechazado el:
          <br />
          <small class="font-weight-bold">
            {{
              moment(getCurrentAnswer.rejected_at).format(
                "DD/MM/YYYY HH:mm [hrs]"
              )
            }}
          </small>
        </p>
        <p v-if="getCurrentAnswer && getCurrentAnswer.rejected_by">
          Rechazada por:
          <br />
          <small class="font-weight-bold">
            {{
              getCurrentAnswer.rejected_by.first_name
                ? `${getCurrentAnswer.rejected_by.first_name} ${getCurrentAnswer.rejected_by.last_name}`
                : "Super Administrador"
            }}
          </small>
        </p>
        <div
          class="d-flex flex-row flex-wrap align-items-center justify-content-center"
        >
          <b-button
            v-if="getCurrentAnswer"
            v-show="
              currentUser &&
              checkIfHaveRole(currentUser.rol, [
                ROLES.inspector,
                ROLES.coordinator,
                ROLES.superadmin,
              ])
            "
            @click="openModalRetrieveAnswer"
            variant="warning"
            class="mt-2"
          >
            Recuperar imagen
          </b-button>
          <b-button
            v-if="ifAnswerIsUploadedByUserAuth()"
            variant="success"
            class="mx-2 mt-2"
            @click="emitReplacementImage"
          >
            {{ loading ? "Cargando Foto" : "Sustituir foto" }}
            <b-spinner
              v-if="loading"
              small
              type="grow"
              class="ml-3 my-2"
            ></b-spinner>
            <i class="ri-camera-3-line lh-2 mx-2" v-if="!loading"></i>
          </b-button>
          <b-button
            v-if="ifAnswerIsUploadedByUserAuth()"
            v-show="
              currentUser &&
              checkIfHaveRole(currentUser.rol, [
                ROLES.inspector,
                ROLES.coordinator,
                ROLES.superadmin,
              ])
            "
            @click="openModalDeleteAnswer"
            variant="danger"
            class="mx-2 mt-2"
          >
            Eliminar Foto
            <i class="ri-delete-bin-2-line lh-2 mx-1"></i>
          </b-button>
          <b-button
            variant="primary"
            class="mx-2 mt-2"
            @click="openModalMarkAnswerReplaced"
            v-if="
              getCurrentAnswer &&
              !getCurrentAnswer.answer_replacement.rejected.length
            "
          >
            {{
              loadingMarkReplaced
                ? "Ejecutando acción.."
                : "Marcar foto como sustituida"
            }}
            <b-spinner
              v-if="loadingMarkReplaced"
              small
              type="grow"
              class="ml-3 my-2"
            ></b-spinner>
            <i class="ri-check-line lh-2 mx-2" v-if="!loadingMarkReplaced"></i>
          </b-button>
        </div>
      </div>
    </section>
    <br />
    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [verifyRole],
  props: {
    answersRejected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment,
      loading: false,
      loadingMarkReplaced: false,
      slide: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    getCurrentAnswer() {
      return this.answersRejected[this.slide]
        ? this.answersRejected[this.slide]
        : null;
    },
  },
  methods: {
    ...mapActions({
      retrieveAnswer: "question/retrieveAnswer",
      sendMarkAnswerReplaced: "question/markAnswerReplaced",
    }),
    emitReplacementImage() {
      this.$emit("replaceCurrrentImage", this.getCurrentAnswer.id);
    },
    ifAnswerIsUploadedByUserAuth() {
      const currentImage = this.getCurrentAnswer;
      if (currentImage) {
        const { place_user } = currentImage;
        return place_user.customuser.id === this.currentUser?.id;
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-answers-rejected");
    },
    openModalRetrieveAnswer() {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(
          "¿Estás seguro de recuperar esta foto?. La imagen se podrá ver nuevamente en las respuestas",
          {
            title: "Atención",
            size: "sm",
            buttonSize: "sm",
            okVariant: "warning",
            okTitle: "Recuperar",
            cancelTitle: "Cancelar",
            cancelVariant: "outline-info-3",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            const resp = await self.retrieveAnswer(self.getCurrentAnswer.id);
            if (resp.status == 200 || resp.status == 201) {
              self.$bvToast.toast(`Foto recuperada correctamente`, {
                title: `Excelente`,
                variant: "primary",
                solid: false,
              });
              setTimeout(() => {
                self.closeModal();
                self.$emit("answerRetrieved");
              }, 1000);
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
    openModalMarkAnswerReplaced() {
      let self = this;
      this.$bvModal
        .msgBoxConfirm("¿Estás seguro de marcar esta foto como sustituida?", {
          title: "Atención",
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "Aceptar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            self.loadingMarkReplaced = true;
            const resp = await self.sendMarkAnswerReplaced({
              id: self.getCurrentAnswer.id,
            });
            if (resp.status == 200 || resp.status == 201) {
              self.$bvToast.toast(
                `Foto marcada como sustituida correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
              setTimeout(() => {
                self.closeModal();
                self.$emit("answerMarkReplaced");
              }, 1000);
            }
            self.loadingMarkReplaced = false;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    openModalDeleteAnswer() {
      let self = this;
      this.$bvModal
        .msgBoxConfirm("Esta acción no se puede revertir", {
          title: "¿Estas seguro de eliminar esta foto?. ",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            setTimeout(() => {
              self.closeModal();
              self.$emit("deleteAnswer", self.getCurrentAnswer.id);
            }, 500);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    openModalAnswerReplacement(answer) {
      this.$emit("emitOpenModalAnswerReplacement", answer);
    },
  },
};
</script>

<style></style>
