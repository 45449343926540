var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-answers-rejected","body-class":"pt-2","hide-footer":"","size":"xl","header-class":"align-items-center"},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('h5',{staticClass:"mb-0"},[_vm._v("Fotos Rechazadas")]),_c('b-button',{staticClass:"btn-close bg-transparent p-0 d-flex align-items-center justify-content-center",staticStyle:{"min-height":"24px"},attrs:{"variant":"text"},on:{"click":_vm.closeModal}},[_c('i',{staticClass:"ri-close-line hp-text-color-dark-0 lh-1",staticStyle:{"font-size":"24px"}})])]}}])},[_c('section',[_c('b-carousel',{staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel-answers-rejected","controls":"","indicators":"","interval":0,"background":"#ababab"},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.answersRejected),function(currentImage){return _c('b-carousel-slide',{key:currentImage.id,scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"d-block mx-auto",staticStyle:{"height":"auto !important","max-height":"700px !important"},attrs:{"src":currentImage.image,"alt":"image slot"}})]},proxy:true}],null,true)},[_c('section',[(
              _vm.getCurrentAnswer &&
              _vm.getCurrentAnswer.answer_replacement.rejected.length
            )?_c('b-button',{staticClass:"mb-2 mx-3 text-white",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.openModalAnswerReplacement(_vm.getCurrentAnswer)}}},[_c('span',{staticClass:"mb-0"},[_vm._v("Sustituida")]),_c('i',{staticClass:"ri-eye-line lh-2 mx-2"}),_vm._v(" Ver ")]):_c('b-badge',{staticClass:"mb-2 mx-3",attrs:{"variant":"success"}},[_c('span',{staticClass:"mb-0 text-white"},[_vm._v("Pendiente de sustitución")])])],1)])}),1),_c('div',{staticClass:"mx-auto text-center"},[(_vm.getCurrentAnswer && _vm.getCurrentAnswer.rejected_comment)?_c('p',{staticClass:"mt-3"},[_vm._v(" Motivo de rechazo: "),_c('br'),_c('small',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getCurrentAnswer.rejected_comment ? _vm.getCurrentAnswer.rejected_comment : "No especificado")+" ")])]):_vm._e(),(_vm.getCurrentAnswer && _vm.getCurrentAnswer.rejected_at)?_c('p',{staticClass:"mt-3"},[_vm._v(" Rechazado el: "),_c('br'),_c('small',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.moment(_vm.getCurrentAnswer.rejected_at).format( "DD/MM/YYYY HH:mm [hrs]" ))+" ")])]):_vm._e(),(_vm.getCurrentAnswer && _vm.getCurrentAnswer.rejected_by)?_c('p',[_vm._v(" Rechazada por: "),_c('br'),_c('small',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getCurrentAnswer.rejected_by.first_name ? `${_vm.getCurrentAnswer.rejected_by.first_name} ${_vm.getCurrentAnswer.rejected_by.last_name}` : "Super Administrador")+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-wrap align-items-center justify-content-center"},[(_vm.getCurrentAnswer)?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.currentUser &&
            _vm.checkIfHaveRole(_vm.currentUser.rol, [
              _vm.ROLES.inspector,
              _vm.ROLES.coordinator,
              _vm.ROLES.superadmin,
            ])
          ),expression:"\n            currentUser &&\n            checkIfHaveRole(currentUser.rol, [\n              ROLES.inspector,\n              ROLES.coordinator,\n              ROLES.superadmin,\n            ])\n          "}],staticClass:"mt-2",attrs:{"variant":"warning"},on:{"click":_vm.openModalRetrieveAnswer}},[_vm._v(" Recuperar imagen ")]):_vm._e(),(_vm.ifAnswerIsUploadedByUserAuth())?_c('b-button',{staticClass:"mx-2 mt-2",attrs:{"variant":"success"},on:{"click":_vm.emitReplacementImage}},[_vm._v(" "+_vm._s(_vm.loading ? "Cargando Foto" : "Sustituir foto")+" "),(_vm.loading)?_c('b-spinner',{staticClass:"ml-3 my-2",attrs:{"small":"","type":"grow"}}):_vm._e(),(!_vm.loading)?_c('i',{staticClass:"ri-camera-3-line lh-2 mx-2"}):_vm._e()],1):_vm._e(),(_vm.ifAnswerIsUploadedByUserAuth())?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.currentUser &&
            _vm.checkIfHaveRole(_vm.currentUser.rol, [
              _vm.ROLES.inspector,
              _vm.ROLES.coordinator,
              _vm.ROLES.superadmin,
            ])
          ),expression:"\n            currentUser &&\n            checkIfHaveRole(currentUser.rol, [\n              ROLES.inspector,\n              ROLES.coordinator,\n              ROLES.superadmin,\n            ])\n          "}],staticClass:"mx-2 mt-2",attrs:{"variant":"danger"},on:{"click":_vm.openModalDeleteAnswer}},[_vm._v(" Eliminar Foto "),_c('i',{staticClass:"ri-delete-bin-2-line lh-2 mx-1"})]):_vm._e(),(
            _vm.getCurrentAnswer &&
            !_vm.getCurrentAnswer.answer_replacement.rejected.length
          )?_c('b-button',{staticClass:"mx-2 mt-2",attrs:{"variant":"primary"},on:{"click":_vm.openModalMarkAnswerReplaced}},[_vm._v(" "+_vm._s(_vm.loadingMarkReplaced ? "Ejecutando acción.." : "Marcar foto como sustituida")+" "),(_vm.loadingMarkReplaced)?_c('b-spinner',{staticClass:"ml-3 my-2",attrs:{"small":"","type":"grow"}}):_vm._e(),(!_vm.loadingMarkReplaced)?_c('i',{staticClass:"ri-check-line lh-2 mx-2"}):_vm._e()],1):_vm._e()],1)])],1),_c('br'),_c('div',{staticClass:"d-flex justify-content-end flex-wrap mt-32"},[_c('b-button',{attrs:{"variant":"outline-info-3"},on:{"click":_vm.closeModal}},[_vm._v(" Cerrar ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }